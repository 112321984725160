<template>
    <div class="page-content">
        <div class="park-form">
            <el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
                <el-form-item label="昵称" prop="authUserName">
                    <el-input clearable v-model="searchObj.nickname" style="width: 220px;"
                              placeholder="请填写姓名"></el-input>
                </el-form-item>
                <el-form-item label="首次时间段" prop="authUserName">
                    <el-date-picker
                            clearable
                            style="width: 220px;"
                            v-model="selectDate"
                            type="daterange"
                            format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd"
                            @change="selectedDate"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="账号" prop="username">
                    <el-input clearable v-model="searchObj.phone" style="width: 220px;" placeholder="请填写电话"></el-input>
                </el-form-item>
                <el-form-item style="margin-top: auto;margin-right: auto;">
                    <div @click="doSearch()" class="fill-btn">查询</div>
                </el-form-item>
                <div>
                <el-form-item style="margin-top: auto;margin-right: auto;">
                    <div  class="fill-btn ">本日新增：{{this.day_count}}</div>
                </el-form-item>
                <el-form-item style="margin-top: auto;margin-right: auto;" >
                    <div  class="fill-btn ">本周新增：{{this.week_count}}</div>
                </el-form-item>
                <el-form-item style="margin-top: auto;margin-right: auto;">
                    <div  class="fill-btn ">本月新增：{{this.month_count}}</div>
                </el-form-item>
                <el-form-item style="margin-top: auto;margin-right: auto;">
                    <div  class="fill-btn ">总用户数量：{{this.total}}</div>
                </el-form-item>
                </div>
                <!--<el-form-item style="margin-top: auto;margin-left: 20px;margin-right: 0;">-->
                <!--<el-button type="danger" @click="getShowSms">短信提醒</el-button>-->
                <!--&lt;!&ndash;<div class="fill-btn " @click="createUser">导入</div>&ndash;&gt;-->
                <!--</el-form-item>-->
                <!--<el-form-item style="margin-top: auto;margin-left: 20px;margin-right: 0;">-->
                <!--<el-button type="info" @click="createUser">新增</el-button>-->
                <!--&lt;!&ndash;<div class="fill-btn" ></div>&ndash;&gt;-->
                <!--</el-form-item>-->
            </el-form>
        </div>
        <div class="table-content">
            <div class="table-top">
                <div class="table-top-text"><!--已选择{{selectData.length}}项--></div>
            </div>
            <el-table
                    :data="listInfo"
                    stripe
                    @selection-change="handleSelectionChange"
                    cell-class-name="text-center"
                    header-cell-class-name="header-row-class-name"
            >
                <el-table-column prop="id" label="ID" min-width="120px">
					<template slot-scope="{row}">
					    {{row.is_vip==2?row.special_card:row.normal_card}}
					</template>
				</el-table-column>
                <el-table-column prop="phone" label="账号" min-width="120px"></el-table-column>
                <el-table-column prop="nickname" label="昵称" min-width="120px"></el-table-column>
                <el-table-column prop="head" label="头像" min-width="100%">
                    <template slot-scope="scope">
                        <img :src="scope.row.head" height="70" />
                    </template>
                </el-table-column>
                <el-table-column width="200 " label="状态">
                    <template slot-scope="{row}">
                        <div class="btn-content">
                            <sapn v-if="row.status == 1" type="primary" round>正常</sapn>
                            <sapn v-if="row.status == 2" type="warning" round>冻结</sapn>
                        </div>
                    </template>
                </el-table-column>
<!--                <el-table-column prop="integral" label="积分余额" min-width="120px"></el-table-column>-->
<!--                <el-table-column prop="price" label="钱包余额" min-width="120px"></el-table-column>-->
<!--                <el-table-column prop="last_time" label="上次登录日期" min-width="120px"></el-table-column>-->
<!--                <el-table-column prop="created_at" label="注册日期" min-width="120px"></el-table-column>-->
                <el-table-column label="操作" min-width="600px">
                    <template template slot-scope="scope">

                        <el-button type="primary" size="mini" @click="couponDate(scope.row)">赠送代金券</el-button>
                        <el-button type="primary" size="mini" @click="storeCouponList(scope.row)">赠送列表</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-content">
                <el-pagination
                        background
                        :current-page.sync="searchObj.page"
                        :page-size="searchObj.limit"
                        @current-change="searchObj.page = $event"
                        @size-change="handleSizeChange"
                        layout="total, prev, pager, next, jumper"
                        :total="total"
                ></el-pagination>
            </div>
        </div>

        <el-dialog title="赠送代金券" :visible.sync="showMoney">
            <!--<HgDialog title="金额流水" :width="1000" :maskClose="false" v-model="showMoney">-->
            <div v-loading="UserLoading" style="padding: 20px;">
                <el-form ref="form" :model="userAdd" label-width="80px">
                    <el-form-item label="代金券">
                        <el-select v-model="user_coupon.coupon_id" clearable placeholder="请选择代金券">
                            <el-option
                                    v-for="item in company"
                                    :key="item.id"
                                    :label="item.note"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="giveCoupon">保存</el-button>
                        <el-button @click="offuser">取消</el-button>
                    </el-form-item>
                </el-form>

            </div>
            <!--</HgDialog>-->
        </el-dialog>
        <el-dialog title="领取列表" :width="1000" :visible.sync="song">
            <div style="padding: 20px;">

                <el-table
                        :data="teamList"
                        stripe
                        cell-class-name="text-center"
                        header-cell-class-name="header-row-class-name"
                >
                    <el-table-column prop="id" label="ID" min-width="120px"></el-table-column>
                    <el-table-column prop="note" label="优惠券" min-width="120px"></el-table-column>
                </el-table>
                <div class="pagination-content">
                    <el-pagination
                            background
                            :current-page.sync="songObj.page"
                            :page-size="songObj.limit"

                            @current-change="songObj.page = $event"
                            @size-change="handleSizeChangeTeam"
                            layout="total, prev, pager, next, jumper"
                            :total="teamListTotal"
                    ></el-pagination>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {delToast, saveToast, formVerify} from './../../utlis/decorators';

    export default {
        name: 'tenant_account',
        props: {
            parkId: {
                type: [Number, String],
                default: null
            }
        },

        data() {
            return {
                //
                song:false,
                MoneyTotal: 0,
                ClassTotal: 0,
                IntegralTotal: 0,
                isShowAgreeButton: false,
                user_id: '',
                day_count:0,
                week_count:0,
                month_count:0,
                UserLoading: false,
                showUser: false,
                addUser: false,
                excelUser: false,
                userAdd: {},
                userInfo: {},
                showClass: false,
                ClassLoading: false,
                searchClassObj: {
                    user_id: '',
                    page: 1,
                    limit: 3
                },
                ClassList: [],
                excelAZ: [],
                searchIntegralObj: {
                    user_id: '',
                    type: '',
                    source: '',
                    page: 1,
                    limit: 3
                },
                showIntegral: false,
                IntegralList: [],
                IntegralLoading: false,
                MoneyLoading: false,
                moneyList: [],
                showTeamList: false,
                TeamLoading: false,
                teamListTotal: 0,

                user_coupon:{
                    user_id:'',
                    coupon_id:'',

                },
                songObj: {
                    page: 1,
                    limit: 2,
                    id: ''
                },
                showMoney: false,
                teamList: [],
                userCreate: [],
                teamData: {},
                loading2: false,
                searchObj: {
                    nickname: '',
                    start_time: '',
                    end_time: '',
                    role: '',
                    phone: '',
                    page: 1,
                    week: 1,
                    limit: 6
                },
                listInfo: [],
                selectDate: [],
                //
                loading: false,
                currentTop: 0,
                total: 0,
                showEdit: false,
                showEditPsd: false,
                editing: false,
                searchForm: {
                    page: 1,
                    limit: 1,
                    nickname: ''
                },
                showTeam: [],
                form: {
                    nickname: ''
                },
                file: null,
                excelSms: false,
                userSms: {},
                price: 0,
                uid: '',
                company: []
            };
        },
        created() {
            this.userList();
            this.colWxcel();
            // this.companyList();
        },

        watch: {
            'searchObj.page'() {
                this.userList();
            },
            'songObj.page'() {

                this.storeCouponList({id:this.songObj.id});
            },
        },
        methods: {


            //头像
            handleAvatarSuccess(res, file) {

                this.userInfo.head = res.url;
                this.userAdd.head = res.url;
                console.log(this.userAdd.head);
            },

            // 信息
            async showUserInfo(item) {
                if (item.is_login === 1) {
                    this.isShowAgreeButton = true
                } else {
                    this.isShowAgreeButton = false
                }
                this.UserLoading = true;
                this.user_id = item.id;
                this.showUser = true;
                let obj = {
                    user_id: this.user_id
                };
                let [e, data] = await this.$api.userRegDetails({id: this.user_id});
                console.log('身份信息', data.data);
                this.userInfo = data.data;

                this.UserLoading = false;
            },

            async createUser() {
                this.addUser = true;

            },

            //赠送列表
            async storeCouponList({id}) {
                this.song=true;
                this.songObj.id = id;
                let [e, data] = await this.$api.storeCouponList(this.songObj);
                if (e) return;
                if (data.code === 200) {

                    console.log(data);
                    this.teamList = data.data.data;
                    this.teamListTotal = data.data.total;
                }
            },
            // 赠送代金券
            async couponDate(row) {
                this.showMoney = true;
                this.user_coupon.user_id = row.id;
                let [e, data] = await this.$api.couponDate();
                if (e) return;
                console.log(data);
                if (data.code === 200) {
                    console.log(data.data);
                    this.company = data.data;
                }
            },
            //导入
            async excelCreate() {
                let form = new FormData();
                form.append('excels', this.file)
                for (let userExcelKey in this.userExcel) {
                    form.append(userExcelKey, this.userExcel[userExcelKey])
                }
                let [e, data] = await this.$api.createExcel(form);
                if (e) return;
                if (data.code == 200) {

                    this.$message.success(data.msg);
                    this.userList();
                }
                this.TeamLoading = false;
            },
            //短信提醒
            async SmsCreate() {
                let [e, data] = await this.$api.userSms(this.userSms);
                if (e) return;
                if (data.code == 200) {
                    this.$message.success(data.msg);

                }
                this.excelSms = false;
            },

            @saveToast()
            async roleStatus(item, status) {
                let [e, data] = await this.$api.userRoleStatus({
                    id: item.id,
                    status: status
                });
                if (e) return;
                if (data.code == 200) {
                    this.userList();
                    this.$message.success(data.msg);

                }

            },
            @saveToast()
            async saveUserTop(item) {
                let [e, data] = await this.$api.saveTop({
                    id: item.id,
                });
                if (e) return;
                if (data.code == 200) {
                    this.userList();
                    this.$message.success(data.msg);
                }
            },
            //积分修改
            async tkWallet(wall) {

                this.excelSms = true;
                this.uid = wall.id;
                this.price = wall.balance;
            },

            async priceSave() {

                let [e, data] = await this.$api.saveWallet({
                    id: this.uid,
                    price: this.price
                });
                if (e) return;
                if (data.code == 200) {
                    this.excelSms = false;
                }

            },

            async colWxcel() {
                let [e, data] = await this.$api.excelColumns();
                if (e) return;
                if (data.code == 200) {
                    this.excelAZ = data.data;
                }
                this.excelSms = false;
            },


            // 我的团队
            async getShowTeamList(item) {
                this.teamData = item;

                this.user_id = item.id;
                this.showTeamList = true;
                this.TeamLoading = true;
                this.songObj.id = this.user_id;
                let [e, data] = await this.$api.userTeamLog(this.songObj);
                if (data.code == 200) {
                    this.teamList = data.data.data;
                    this.teamListTotal = data.data.total

                    console.log('流水信息', data);
                }
                this.TeamLoading = false;

            },
            //导出
            async userExport() {
                let a = document.createElement('a');
                a.href = 'http://www.urxms.com/user.xls';
                a.tartget = '_blank';
                a.click();
                // this.$downFile('userExport',this.searchForm,'用户模板');
            },
            async getShowExcel() {

                this.excelUser = true;


            },
            async getShowSms() {

                this.excelSms = true;
                // this.TeamLoading = true;
                // this.songObj.id = this.user_id;
                //
                // if (data.code == 200) {
                //     this.teamList = data.data.data;
                //     this.teamListTotal = data.data.total
                //
                //     console.log('流水信息', data);
                // }
                // this.TeamLoading = false;

            },


            // 用户类型
            isRole(e) {
                switch (e.role) {
                    case 1:
                        return '游客';
                    case 2:
                        return '客户经理';
                    case 3:
                        return '签约经理';
                    case 4:
                        return '普通用户';
                    default:
                        break;
                }
            },
            async offuser() {
                this.showUser = false;
                this.addUser = false;
                this.excelSms = false;
                this.excelUser = false;
            },
            // 搜索
            doSearch() {
                this.userList();
            },

            // 选择日期
            selectedDate(e) {
                if (e) {
                    this.searchObj.start_time = e[0];
                    this.searchObj.end_time = e[1];
                    console.log(this.searchObj);
                } else {
                    this.searchObj.start_time = '';
                    this.searchObj.end_time = '';
                }
            },
            //页面数量改变
            handleSizeChange(val) {
                this.searchForm.page = 1;
                this.searchForm.limit = val;
            },
            //页面数量改变
            handleSizeChangeTeam(val) {
                this.songObj.page = 1;
                this.songObj.limit = val;
            },
            handleSelectionChange(val) {
                this.selectData = val;
            },


            async getAdminList() {
                this.loading = true;
                let [e, data] = await this.$api.adminListApi(this.searchForm);
                this.loading = false;
                if (e) return;
                if (data.code === 200) {
                    this.tableData = data.data.data;
                    this.total = data.data.total;
                }
            },
            //用户列表
            async userList() {
                this.loading = true;
                let [e, data] = await this.$api.storeUser(this.searchObj);
                console.log('用户列表', data.data.data);
                if (data.code === 200) {
                    this.listInfo = data.data.data;
                    this.total = data.data.total;
                    this.day_count = data.user_count.day_count;
                    this.week_count = data.user_count.week_count;
                    this.month_count = data.user_count.month_count;
                    this.loading = false;
                }
            },
            //公司列表

            // async companyList() {
            //
            //     let [e, data] = await this.$api.companyList();
            //     console.log('用户列表', data.data.data);
            //     if (data.code === 200) {
            //         this.company = data.data;
            //     }
            // },
            async userWalletLog() {
                this.$downFile('/admin/user/wallet/log/excel', this.searchMoneyObj, '钱包记录');

            },
            //用户修改
            async saveUser() {
                this.loading = true;
                let [e, data] = await this.$api.saveUser(this.userInfo);
                console.log(data);
                if (e) return;
                if (data.code === 200) {
                    this.userList();
                    this.$message.success(data.msg);
                    this.loading = false;
                    this.showUser = false;
                    this.UserLoading = false;
                }
            },
            //添加用户
            async giveCoupon() {

                this.loading = true;
                let [e, data] = await this.$api.giveCoupon(this.user_coupon);
                console.log(this.user_coupon);
                if (e) return;
                if (data.code === 200) {

                    this.$message.success(data.msg);

                    this.showMoney = false;
                }
            },


        }
    };
</script>
<style lang="less">
    .el-table__row:hover .icon-bianji {
        color: rgb(0, 185, 217) !important;
    }

    .el-table__row:hover .icon-shanchu {
        color: rgb(255, 92, 92) !important;
    }
</style>
<style scoped lang="less">
    .page-content {
        width: calc(~'100% - 60px');
        margin: 26px 30px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 4px;
        padding: 20px 30px;

        .park-form {
            border-bottom: 1px solid #efefef;
        }

        .table-content {
            padding-top: 20px;

            .table-top {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .table-top-text {
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(35, 39, 52, 1);
                }

                .table-top-btn {
                }
            }
        }
    }

    .btn-content {
        display: flex;
        justify-content: center;
        align-items: center;

        .iconfont {
            font-size: 20px;
            color: rgb(190, 199, 213);
            margin: 0 10px;
            cursor: pointer;
        }

        .icon-shanchu {
            font-size: 24px;
        }
    }

    .box-shadow {
        width: calc(~'100% + 40px');
        height: 50px;
        position: absolute;
        top: 0;
        left: -20px;
        z-index: 10;
        box-shadow: 0 0 4px #b4b4b4;
    }
</style>
